import React from "react"
import Intro from "./Intro"

const Hero = () => {
  return (
    <div>
      <Intro />
    </div>
  )
}

export default Hero
